import circularWoff from '../fonts/Circular-Fonts/Fonts/circular-book.woff';
import circularMediumWoff from '../fonts/Circular-Fonts/Fonts/circular-medium.woff';
import circularBoldWoff from '../fonts/Circular-Fonts/Fonts/circular-bold.woff';
import circularBlackWoff from '../fonts/Circular-Fonts/Fonts/circular-black.woff';
import openSansWoff from '../fonts/Open-Sans/open-sans-v20-latin-regular.woff';
import openSansMediumWoff from '../fonts/Open-Sans/open-sans-v20-latin-600.woff';
import openSansBoldWoff from '../fonts/Open-Sans/open-sans-v20-latin-700.woff';
import openSansBlackWoff from '../fonts/Open-Sans/open-sans-v20-latin-800.woff';

type MuiCssBaselineType = { [k: string]: any };

const circular = {
  fontFamily: 'Circular',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Circular'),
    local('Circular-Regular'),
    url(${circularWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const circularMedium = {
  fontFamily: 'CircularMedium',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('CircularMedium'),
    local('Circular-Medium'),
    url(${circularMediumWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const circularBold = {
  fontFamily: 'CircularBold',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('CircularBold'),
    local('Circular-Bold'),
    url(${circularBoldWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const circularBlack = {
  fontFamily: 'CircularBlack',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('CircularBlack'),
    local('Circular-Black'),
    url(${circularBlackWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const openSans = {
  fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Open-Sans'),
    local('Open-Sans-Regular'),
    url(${openSansWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};


const openSansMedium = {
  fontFamily: 'Open Sans Medium',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `
    local('OpenSansMedium'),
    local('Open-Sans-Medium'),
    url(${openSansMediumWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const openSansBold = {
  fontFamily: 'Open Sans Bold',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `
    local('OpenSansBold'),
    local('Open-Sans-Bold'),
    url(${openSansBoldWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const openSansBlack = {
  fontFamily: 'Open Sans Black',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `
    local('OpenSansBlack'),
    local('Open-Sans-Black'),
    url(${openSansBlackWoff}) format('woff')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const MuiCssBaseline: MuiCssBaselineType = {
  '@global': {
    '@font-face': [circular, circularMedium, circularBold, circularBlack, openSans, openSansMedium, openSansBold, openSansBlack],
    '*::-webkit-scrollbar': {
      width: '0.6em',
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey',
    },
  },
};

export default MuiCssBaseline;
