import { routes } from './routes';
import { MenuItem } from '../../../../commons/ui/menu';

export const catalogMenu: MenuItem = {
  key: 'publicCatalog',
  basePath: '/catalog/:publicationId',
  enabled: true,
  icon: 'List',
  text: 'Catalog',
  children: routes,
};
